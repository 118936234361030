<template>
  <div class="preview-fix">
    <MobilePreview deviceSetable>
      <HbPreview :hb="hb" :audioInfo="audioInfo" :hasSider="false"></HbPreview>
      <template v-slot:right-toolbar>
        <EditorSider
          v-if="hasSider"
          :activeIndex.sync="currentIndex"
          :len="len"
          :modifiable="false"
        />
      </template>
      <template v-slot:bottom-toolbar>
        <div class="bottom">
          <Button v-if="showPushApp" @click="pushToApp" icon="ios-send" size="small"></Button>
        </div>
      </template>
    </MobilePreview>
  </div>
</template>

<script>
import computeLenMixin from "./computeLenMixin4wrapper";
import axios from "../api/newAxiosProxy";
import UploadImgService from "../views/components-for-editor/uploadBatch4Img";
export default {
  props: {
    hb: {},
    audioInfo: {},
    hasSider: {
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    serialNo() {
      return this.$store.getters.getDeviceSerialNumber;
    },
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    isPublished() {
      return ["3", "4"].includes(this.hb.status);
    },
    showPushApp() {
      if(!this.serialNo){
        return false;
      }
      // 配图的韵文 非已发布 ，需要显示推送
      // 配图的已发布
      // 6审的所有
      if(6 === this.roleCode){
        return true;
      }     
      return 4 === this.roleCode && (this.isPublished || /^(cn|en)Verse$/.test(this.hb.contentType));
    },
  },
  methods: {
    async pictureWaitdealPush() {
      await UploadImgService(this.hb,false).catch((err) => {
        this.$Message.warning(err.message || err);
      });
      this.hb.index = this.currentIndex;
      // 待处理推送会改原数据
      // 适合修改内容推送（已发布重写-会改原数据，待处理修改-会改原数据）
      return axios(
        {
          method: "post",
          url: "/contentExt/preReleaseTxt",
          data: this.hb,
        },
        true
      );

      // // 适合不修改内容的推送
      // axios({
      //   url:'/contentExt/previewContent'
      // })
    },
    pushToApp() {
      // console.log(this.roleCode, this.hb.status, this.serialNo);
      if (!this.showPushApp) {
        return;
      }

      this.pictureWaitdealPush().then(() => {
        this.$Message.success("推送成功");
      });
    },
  },
  mixins: [computeLenMixin],
  mounted() {
    this.hb.taskName = this.roleCode;
    this.pushToApp();
  },
  watch: {
    currentIndex() {
      this.pushToApp();
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100%;
  width: 100%;
}
.preview-fix {
  position: sticky;
  z-index: 99999;
  top: 60px;
  height: max-content;
  width: min-content;
}
.bottom {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom /deep/ .ivu-btn-icon-only.ivu-btn-small {
  font-size: 16px;
}
</style>